import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { useTheme } from '../context/ThemeContext';
import CelebrationAnimation from './CelebrationAnimation';
import AnimeEffects from './AnimeEffects';

const CountdownTimer = ({ targetDate }) => {
  const { isDarkMode } = useTheme();
  const [timeState, setTimeState] = useState({
    isMarried: false,
    timeLeft: {
      years: 0,
      months: 0,
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0
    }
  });

  useEffect(() => {
    const calculateTime = () => {
      const now = new Date();
      const target = new Date(targetDate);
      const difference = target - now;
      
      if (difference <= 0) {
        // Calculate time since marriage
        const start = new Date(targetDate);
        const end = new Date();

        // Calculate the time difference
        let years = end.getFullYear() - start.getFullYear();
        let months = end.getMonth() - start.getMonth();
        let days = end.getDate() - start.getDate();

        // Adjust for negative days
        if (days < 0) {
          // Get the last day of the previous month
          const lastMonth = new Date(end.getFullYear(), end.getMonth(), 0);
          days += lastMonth.getDate();
          months--;
        }

        // Adjust for negative months
        if (months < 0) {
          months += 12;
          years--;
        }

        // If years is negative, reset everything to 0
        if (years < 0) {
          years = 0;
          months = 0;
          days = 0;
        }

        const timeSince = Math.abs(difference);
        setTimeState({
          isMarried: true,
          timeLeft: {
            years,
            months,
            days,
            hours: Math.floor((timeSince / (1000 * 60 * 60)) % 24),
            minutes: Math.floor((timeSince / 1000 / 60) % 60),
            seconds: Math.floor((timeSince / 1000) % 60)
          }
        });
      } else {
        // Calculate countdown to wedding
        const total = difference / (1000 * 60 * 60 * 24); // Total days
        const years = Math.floor(total / 365);
        let remainingDays = total % 365;
        
        // Calculate months more accurately (approximating month as 30.44 days)
        const months = Math.floor(remainingDays / 30.44);
        remainingDays = Math.floor(remainingDays % 30.44);

        setTimeState({
          isMarried: false,
          timeLeft: {
            years,
            months,
            days: Math.floor(remainingDays),
            hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
            minutes: Math.floor((difference / 1000 / 60) % 60),
            seconds: Math.floor((difference / 1000) % 60)
          }
        });
      }
    };

    calculateTime();
    const timer = setInterval(calculateTime, 1000);
    return () => clearInterval(timer);
  }, [targetDate]);

  const getPostMarriageMessage = () => {
    const { years, months } = timeState.timeLeft;
    
    if (isDarkMode) {
      if (years === 0 && months === 0) return "Our eternal bond begins today";
      if (years === 0) return `${months} month${months > 1 ? 's' : ''} of eternal happiness`;
      if (years === 1) return "One year of infinite love";
      return `${years} years of eternal companionship`;
    } else {
      if (years === 0 && months === 0) return "Beginning our greatest adventure";
      if (years === 0) return `${months} month${months > 1 ? 's' : ''} into our grand voyage`;
      if (years === 1) return "One year of sailing together";
      return `${years} years of exploring life together`;
    }
  };

  const getTimeDescription = () => {
    if (!timeState.isMarried) {
      return isDarkMode
        ? "Time until our eternal union"
        : "Time until our grand adventure begins";
    }
    return isDarkMode
      ? "Time in our eternal journey"
      : "Time in our amazing adventure";
  };

  const getUnitLabel = (unit, value) => {
    if (timeState.isMarried) {
      return `${unit} together`;
    }
    return `${unit}`;
  };

  const SharinganLoader = () => (
    <motion.div
      className="w-16 h-16 border-4 rounded-full border-red-500"
      style={{
        borderTopColor: 'transparent',
        borderRightColor: 'rgba(239, 68, 68, 0.5)',
      }}
      animate={{ rotate: 360 }}
      transition={{ duration: 2, repeat: Infinity, ease: "linear" }}
    />
  );

  const PirateLoader = () => (
    <motion.div
      className="w-16 h-16 border-4 rounded-full border-yellow-300"
      style={{
        borderTopColor: 'transparent',
        borderRightColor: 'rgba(252, 211, 77, 0.5)',
      }}
      animate={{ rotate: 360 }}
      transition={{ duration: 2, repeat: Infinity, ease: "linear" }}
    />
  );

  return (
    <div 
      className="min-h-screen w-full flex items-center justify-center bg-cover bg-center relative overflow-hidden"
      style={{ backgroundImage: 'url(https://static.dhrumilpatel.in/images/bg.jpg)' }}
    >
      <div className={`absolute inset-0 ${
        isDarkMode 
          ? 'bg-gradient-to-b from-black/70 to-red-900/30'
          : 'bg-gradient-to-b from-black/50 to-blue-900/30'
      } backdrop-blur-sm`} />
      
      <AnimeEffects isDarkMode={isDarkMode} />
      {timeState.isMarried && <CelebrationAnimation />}
      
      <div className="relative z-20 w-full max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-8 text-center">
        <motion.div
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          className={`mb-6 text-lg sm:text-xl md:text-2xl ${
            isDarkMode 
              ? 'font-yuji text-red-400'
              : 'font-hachi text-yellow-300'
          }`}
        >
          {timeState.isMarried 
            ? getPostMarriageMessage()
            : (isDarkMode ? "Eternal Flames of Love" : "Adventure of a Lifetime")
          }
        </motion.div>

        <motion.div
          className="relative mb-8"
          initial={{ opacity: 0, scale: 0.9 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.5 }}
        >
          <motion.h1 
            className={`text-3xl sm:text-5xl md:text-7xl ${
              isDarkMode ? 'font-yuji text-red-500' : 'font-tokyo text-yellow-300'
            }`}
          >
            Dhrumil & Priya
          </motion.h1>
          
          {isDarkMode ? (
            <motion.div
              className="absolute -top-4 left-1/2 transform -translate-x-1/2"
              animate={{ rotate: [0, 360] }}
              transition={{ duration: 20, repeat: Infinity, ease: "linear" }}
            >
              <div className="w-64 h-64 border-2 border-red-500/20 rounded-full" />
              <div className="absolute inset-0 border-2 border-red-500/10 rounded-full transform rotate-45" />
            </motion.div>
          ) : (
            <motion.div
              className="absolute -top-4 left-1/2 transform -translate-x-1/2"
              animate={{ rotate: [0, 360] }}
              transition={{ duration: 20, repeat: Infinity, ease: "linear" }}
            >
              <div className="w-64 h-64 border-2 border-yellow-300/20 rounded-full" />
              <div className="absolute inset-0 border-2 border-yellow-300/10 rounded-full transform rotate-45" />
            </motion.div>
          )}
        </motion.div>

        <motion.p 
          className={`text-xl sm:text-2xl md:text-3xl mb-8 text-white/80 ${
            isDarkMode ? 'font-yuji' : 'font-hachi'
          }`}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.3 }}
        >
          {getTimeDescription()}
        </motion.p>

        <div className="grid grid-cols-2 sm:flex sm:justify-center gap-3 sm:gap-4 md:gap-8">
          {Object.entries(timeState.timeLeft).map(([unit, value], index) => (
            <motion.div
              key={unit}
              className={`p-2 sm:p-4 md:p-6 rounded-lg backdrop-blur-sm ${
                isDarkMode 
                  ? 'bg-gradient-to-br from-red-900/20 to-black/40 border-red-500/20' 
                  : 'bg-gradient-to-br from-blue-500/20 to-blue-900/40 border-yellow-300/20'
              } border-2`}
              initial={{ scale: 0 }}
              animate={{ scale: 1 }}
              transition={{ delay: index * 0.1 }}
              whileHover={{ scale: 1.05 }}
            >
              <motion.div 
                className={`text-xl sm:text-3xl md:text-5xl ${
                  isDarkMode ? 'text-red-400' : 'text-yellow-300'
                } font-metal`}
                animate={{ scale: [1, 1.1, 1] }}
                transition={{ duration: 2, repeat: Infinity }}
              >
                {value}
              </motion.div>
              <div className="text-white text-xs sm:text-sm md:text-base uppercase">
                {getUnitLabel(unit, value)}
              </div>
            </motion.div>
          ))}
        </div>

        <motion.p 
          className={`mt-8 text-lg sm:text-xl ${
            isDarkMode ? 'text-red-400/80' : 'text-yellow-300/80'
          } font-metal`}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.5 }}
        >
          {new Date(targetDate).toLocaleDateString('en-US', {
            weekday: 'long',
            year: 'numeric',
            month: 'long',
            day: 'numeric'
          })}
        </motion.p>
      </div>
    </div>
  );
};

export default CountdownTimer;