import React, { useEffect, useState } from 'react';

const SectionScroll = () => {
  const [isLastSection, setIsLastSection] = useState(false);
  const [userInteracted, setUserInteracted] = useState(false);

  useEffect(() => {
    let scrollInterval;
    console.log('🔄 SectionScroll mounted');

    // Extra margin from header (in pixels)
    const SCROLL_MARGIN = 24;

    // Get header height dynamically plus margin
    const getHeaderOffset = () => {
      const header = document.querySelector('.fixed.top-0');
      return (header ? header.offsetHeight : 0) + SCROLL_MARGIN;
    };

    // Get all sections
    const getAllSections = () => [
      document.querySelector('.countdown-section'),
      ...Array.from(document.querySelectorAll('.timeline-entry'))
    ].filter(Boolean);

    // Get currently visible section
    const getCurrentSection = () => {
      const sections = getAllSections();
      const viewportMiddle = window.innerHeight / 2;
      
      return sections.find(section => {
        const rect = section.getBoundingClientRect();
        return rect.top <= viewportMiddle && rect.bottom >= viewportMiddle;
      }) || sections[0];
    };

    // Check if this is the last section
    const checkIsLastSection = (currentSection) => {
      const sections = getAllSections();
      return sections.indexOf(currentSection) === sections.length - 1;
    };

    // Scroll to next section with offset
    const scrollToNextSection = () => {
      if (userInteracted || isLastSection) return;

      const currentSection = getCurrentSection();
      if (!currentSection) return;

      const sections = getAllSections();
      const currentIndex = sections.indexOf(currentSection);
      const nextSection = sections[currentIndex + 1];

      if (nextSection) {
        console.log('📜 Scrolling to next section');
        const headerOffset = getHeaderOffset();
        const nextSectionRect = nextSection.getBoundingClientRect();
        const scrollPosition = window.scrollY + nextSectionRect.top - headerOffset;

        window.scrollTo({
          top: scrollPosition,
          behavior: 'smooth'
        });
        
        if (checkIsLastSection(nextSection)) {
          console.log('🏁 Reached last section');
          setIsLastSection(true);
          if (scrollInterval) {
            clearInterval(scrollInterval);
          }
        }
      }
    };

    // Handle menu clicks and user interactions
    const handleUserInteraction = (e) => {
      if (e.isTrusted) {
        console.log('👆 User interaction detected - stopping auto scroll');
        setUserInteracted(true);
        if (scrollInterval) {
          clearInterval(scrollInterval);
        }
      }
    };

    // Handle menu button clicks specifically
    const handleMenuClick = () => {
      console.log('📱 Menu clicked - stopping auto scroll');
      setUserInteracted(true);
      if (scrollInterval) {
        clearInterval(scrollInterval);
      }
    };

    // Add listeners to menu buttons
    const menuButtons = document.querySelectorAll('nav button');
    menuButtons.forEach(button => {
      button.addEventListener('click', handleMenuClick);
    });

    // Add general interaction listeners
    document.addEventListener('wheel', handleUserInteraction, { passive: true });
    document.addEventListener('touchstart', handleUserInteraction, { passive: true });
    document.addEventListener('mousedown', handleUserInteraction, { passive: true });
    document.addEventListener('keydown', handleUserInteraction, { passive: true });

    // Start scrolling after initial delay if user hasn't interacted
    const startTimer = setTimeout(() => {
      if (!userInteracted) {
        console.log('▶️ Starting auto scroll');
        scrollInterval = setInterval(() => {
          if (!userInteracted && !isLastSection) {
            scrollToNextSection();
          }
        }, 5000);
      }
    }, 2000);

    // Cleanup
    return () => {
      console.log('🔄 Cleaning up SectionScroll');
      clearTimeout(startTimer);
      if (scrollInterval) {
        clearInterval(scrollInterval);
      }
      
      // Remove all event listeners
      menuButtons.forEach(button => {
        button.removeEventListener('click', handleMenuClick);
      });
      document.removeEventListener('wheel', handleUserInteraction);
      document.removeEventListener('touchstart', handleUserInteraction);
      document.removeEventListener('mousedown', handleUserInteraction);
      document.removeEventListener('keydown', handleUserInteraction);
    };
  }, [isLastSection, userInteracted]);

  return null;
};

export default SectionScroll;