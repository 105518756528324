import React from 'react';
import { motion } from 'framer-motion';
import { Sparkle, Heart, Star } from 'lucide-react';
import { useTheme } from '../context/ThemeContext';

const CelebrationAnimation = () => {
  const { isDarkMode } = useTheme();
  const particleCount = 30;
  const particles = Array.from({ length: particleCount });
  const icons = [Sparkle, Heart, Star];
  
  // Define color based on theme
  const particleColor = isDarkMode ? 'text-red-400' : 'text-yellow-300';
  
  return (
    <div className="absolute inset-0 pointer-events-none overflow-hidden">
      {/* Firework particles */}
      {particles.map((_, index) => {
        const Icon = icons[Math.floor(Math.random() * icons.length)];
        return (
          <motion.div
            key={index}
            className="absolute"
            initial={{
              opacity: 0,
              scale: 0,
              x: '50%',
              y: '50%',
            }}
            animate={{
              opacity: [0, 1, 0],
              scale: [0, 1, 0],
              x: `${50 + Math.random() * 100 - 50}%`,
              y: `${50 + Math.random() * 100 - 50}%`,
            }}
            transition={{
              duration: 2,
              repeat: Infinity,
              delay: index * 0.1,
              ease: "easeOut"
            }}
          >
            <Icon className={`w-4 h-4 ${particleColor}`} />
          </motion.div>
        );
      })}
      
      {/* Celebration icons at the top */}
      <div className="absolute top-0 inset-x-0 flex justify-center gap-4">
        {[0, 1, 2].map((i) => (
          <motion.div
            key={i}
            animate={{
              y: [0, -20, 0],
              rotate: [-10, 10, -10]
            }}
            transition={{
              duration: 2,
              repeat: Infinity,
              delay: i * 0.3
            }}
          >
            <Heart className={`w-12 h-12 ${particleColor}`} />
          </motion.div>
        ))}
      </div>
    </div>
  );
};

export default CelebrationAnimation;