import React, { useState, useEffect, useRef } from 'react';
import { Music, Pause, Volume2, VolumeX } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';
import { useTheme } from '../context/ThemeContext';

const BackgroundMusic = () => {
  const { isDarkMode } = useTheme();
  const [isPlaying, setIsPlaying] = useState(false);
  const [isMuted, setIsMuted] = useState(false);
  const audioRef = useRef(null);
  const buttonRef = useRef(null);

  useEffect(() => {
    audioRef.current = new Audio('https://static.dhrumilpatel.in/music/suzume.mp3');
    audioRef.current.loop = true;

    // Wait for page to load completely
    window.addEventListener('load', () => {
      // Add a small delay to ensure everything is ready
      setTimeout(() => {
        // Simulate click on the play button
        if (buttonRef.current) {
          buttonRef.current.click();
        }
      }, 1000); // 1 second delay
    });

    return () => {
      if (audioRef.current) {
        audioRef.current.pause();
      }
    };
  }, []);

  const togglePlay = async () => {
    if (!audioRef.current) return;

    try {
      if (isPlaying) {
        audioRef.current.pause();
        setIsPlaying(false);
      } else {
        const playPromise = audioRef.current.play();
        if (playPromise !== undefined) {
          playPromise
            .then(() => {
              setIsPlaying(true);
            })
            .catch(error => {
              console.error("Play prevented:", error);
              setIsPlaying(false);
            });
        }
      }
    } catch (error) {
      console.error('Toggle play error:', error);
    }
  };

  const toggleMute = () => {
    if (audioRef.current) {
      audioRef.current.muted = !audioRef.current.muted;
      setIsMuted(!isMuted);
    }
  };

  return (
    <div className="fixed bottom-4 right-4 z-50 flex items-center space-x-2">
      <motion.div
        className="flex items-center gap-2"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
      >
        <motion.button
          ref={buttonRef} // Add ref to the button
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
          onClick={togglePlay}
          className={`p-3 rounded-full ${
            isDarkMode ? 'bg-red-900/20' : 'bg-blue-900/20'
          } backdrop-blur-sm transition-all duration-300 hover:bg-opacity-30`}
        >
          {isPlaying ? (
            <Pause className={`w-6 h-6 ${
              isDarkMode ? 'text-red-400' : 'text-yellow-300'
            }`} />
          ) : (
            <Music className={`w-6 h-6 ${
              isDarkMode ? 'text-red-400' : 'text-yellow-300'
            }`} />
          )}
        </motion.button>

        {/* Volume Button */}
        {isPlaying && (
          <motion.button
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
            onClick={toggleMute}
            className={`p-3 rounded-full ${
              isDarkMode ? 'bg-red-900/20' : 'bg-blue-900/20'
            } backdrop-blur-sm transition-all duration-300 hover:bg-opacity-30`}
          >
            {isMuted ? (
              <VolumeX className={`w-6 h-6 ${
                isDarkMode ? 'text-red-400' : 'text-yellow-300'
              }`} />
            ) : (
              <Volume2 className={`w-6 h-6 ${
                isDarkMode ? 'text-red-400' : 'text-yellow-300'
              }`} />
            )}
          </motion.button>
        )}
      </motion.div>

      {/* Now Playing Text */}
      <AnimatePresence>
        {isPlaying && (
          <motion.div
            initial={{ opacity: 0, x: 20 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: 20 }}
            className={`absolute right-full mr-4 text-sm ${
              isDarkMode ? 'text-red-400' : 'text-yellow-300'
            } whitespace-nowrap hidden md:block`}
          >
            Now Playing: Suzume no Tojimari
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default BackgroundMusic;