import React, { useEffect, useState, useRef } from 'react';
import { useTheme } from '../context/ThemeContext';

const TimelineImage = ({ src, alt }) => {
  const { isDarkMode } = useTheme();
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  const [imageLoaded, setImageLoaded] = useState(false);
  const canvasRef = useRef(null);
  const imageRef = useRef(null);

  useEffect(() => {
    // Reset states when src changes
    setIsLoading(true);
    setError(false);
    setImageLoaded(false);

    const img = new Image();
    img.onload = () => {
      handleImageLoad(img);
    };
    img.onerror = handleImageError;
    img.src = src;

    // Store reference to image for cleanup
    imageRef.current = img;

    return () => {
      // Cleanup
      if (imageRef.current) {
        imageRef.current.onload = null;
        imageRef.current.onerror = null;
      }
    };
  }, [src]);

  const handleImageLoad = (img) => {
    try {
      updateDimensions(img);
      setImageLoaded(true);
      setIsLoading(false);
    } catch (err) {
      console.error('Error handling image load:', err);
      setError(true);
      setIsLoading(false);
    }
  };

  const handleImageError = () => {
    console.error('Error loading image:', src);
    setError(true);
    setIsLoading(false);
  };

  const updateDimensions = (img) => {
    try {
      const viewportWidth = window.innerWidth;
      const maxWidth = Math.min(viewportWidth * 0.9, 800);
      const maxHeight = window.innerHeight * 0.4;

      const aspectRatio = img.width / img.height;
      let newWidth, newHeight;

      if (img.height * aspectRatio > maxWidth) {
        newWidth = maxWidth;
        newHeight = maxWidth / aspectRatio;
      } else {
        newHeight = Math.min(maxHeight, img.height);
        newWidth = newHeight * aspectRatio;
      }

      setDimensions({ width: newWidth, height: newHeight });
    } catch (err) {
      console.error('Error updating dimensions:', err);
      setError(true);
    }
  };

  useEffect(() => {
    const handleResize = debounce(() => {
      if (imageRef.current && imageRef.current.complete) {
        updateDimensions(imageRef.current);
      }
    }, 200);

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  function debounce(func, wait) {
    let timeout;
    return function executedFunction(...args) {
      const later = () => {
        clearTimeout(timeout);
        func(...args);
      };
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
    };
  }

  if (error) {
    return (
      <div className="w-full h-48 flex items-center justify-center text-white">
        Failed to load image
      </div>
    );
  }

  return (
    <div 
      className="relative w-full flex justify-center bg-black/40 p-2 sm:p-4 select-none"
      onContextMenu={(e) => e.preventDefault()}
      onMouseDown={(e) => e.preventDefault()}
    >
      <div className="relative rounded-lg overflow-hidden">
        {/* Loading Spinner */}
        {isLoading && (
          <div className="absolute inset-0 flex items-center justify-center bg-black/40">
            <div className={`animate-spin w-8 h-8 border-4 rounded-full ${
              isDarkMode ? 'border-red-500 border-t-transparent' : 'border-yellow-300 border-t-transparent'
            }`} />
          </div>
        )}

        {/* Image */}
        <img
          src={src}
          alt={alt}
          style={{
            width: '100%',
            height: 'auto',
            maxWidth: `${dimensions.width}px`,
            maxHeight: `${dimensions.height}px`,
            opacity: imageLoaded ? 1 : 0,
            transition: 'opacity 0.3s ease-in-out',
            WebkitUserSelect: 'none',
            WebkitTouchCallout: 'none',
            pointerEvents: 'none',
          }}
          className="rounded-lg object-contain"
          onLoad={(e) => {
            setImageLoaded(true);
            setIsLoading(false);
          }}
          onError={handleImageError}
          draggable="false"
          loading="lazy"
          decoding="async"
        />

        {/* Protective overlay */}
        <div 
          className="absolute inset-0 select-none pointer-events-none"
          style={{
            WebkitUserSelect: 'none',
            WebkitTouchCallout: 'none',
            content: '',
          }}
          aria-hidden="true"
        />
      </div>

      {/* Watermark */}
      <div 
        className="absolute bottom-4 right-6 text-white/50 text-xs font-medium rotate-[-30deg] pointer-events-none"
        style={{ 
          textShadow: '1px 1px 2px rgba(0,0,0,0.5)',
          WebkitUserSelect: 'none',
          userSelect: 'none'
        }}
      >
        © D&P Wedding
      </div>
    </div>
  );
};

export default TimelineImage;