import React from 'react';
import { Heart } from 'lucide-react';
import { useTheme } from '../context/ThemeContext';
import { motion } from 'framer-motion';

const Footer = () => {
  const { isDarkMode } = useTheme();

  return (
    <footer className={`py-8 ${
      isDarkMode ? 'bg-red-900/20' : 'bg-blue-900/20'
    } backdrop-blur-sm`}>
      <div className="container mx-auto px-4">
        <div className="text-center space-y-4">
          <div className={`${
            isDarkMode ? 'font-yuji text-red-400' : 'font-tokyo text-yellow-300'
          } text-xl md:text-2xl`}>
            November 16, 2024
          </div>
          
          <motion.div 
            className="text-gray-400 flex items-center justify-center gap-2"
            animate={{
              scale: [1, 1.02, 1],
              transition: { duration: 2, repeat: Infinity }
            }}
          >
            <span className={`${
              isDarkMode ? 'font-yuji' : 'font-hachi'
            }`}>
              Created with
            </span>
            <Heart className="inline-block w-4 h-4 text-red-500" />
            <span className={`${
              isDarkMode ? 'font-yuji' : 'font-hachi'
            }`}>
              for our special day
            </span>
          </motion.div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;