import React, { useState } from 'react';
import { Moon, Sun, Clock, Image, Video, Menu, X } from 'lucide-react';
import { useTheme } from './context/ThemeContext';
import WeddingTimeline from './components/WeddingTimeline';
import BackgroundMusic from './components/BackgroundMusic';

const App = () => {
  const { isDarkMode, toggleTheme } = useTheme();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [userInteracted, setUserInteracted] = useState(false);

  const scrollToSection = (sectionId) => {
    setUserInteracted(true);
    window.dispatchEvent(new CustomEvent('menuNavigation', {
      detail: { sectionId }
    }));
    const element = document.getElementById(sectionId);
    if (element) {
      element.scrollIntoView({ 
        behavior: 'smooth',
        block: 'start'
      });
    }
    setIsMenuOpen(false);
  };

  const handleNavClick = (sectionId) => {
    return (e) => {
      e.preventDefault();
      console.log('🎯 Navigation clicked:', sectionId);
      scrollToSection(sectionId);
    };
  };

  return (
    <div className="min-h-screen">
      <BackgroundMusic />
      {/* Background Image */}
      <div 
        className="fixed inset-0 bg-cover bg-center z-0"
        style={{ backgroundImage: 'url(https://static.dhrumilpatel.in/images/bg.jpg)' }}
      >
        <div className={`absolute inset-0 ${
          isDarkMode 
            ? 'bg-gradient-to-br from-red-900/90 via-gray-900/90 to-black/90' 
            : 'bg-gradient-to-br from-blue-400/80 via-blue-600/80 to-blue-800/80'
        }`} />
      </div>

      {/* Header with Logo, Menu and Theme Toggle */}
      <div className="fixed top-0 left-0 right-0 z-50 bg-black/20 backdrop-blur-sm">
        <div className="container mx-auto px-4 py-2 flex justify-between items-center">
          {/* Logo */}
          <div className="flex items-center gap-2">
            <img 
              src="https://static.dhrumilpatel.in/images/logo1.png" 
              alt="D&P Wedding" 
              className="h-12 w-auto"
            />
          </div>

          {/* Mobile Menu Button */}
          <button
            onClick={() => setIsMenuOpen(!isMenuOpen)}
            className="md:hidden"
          >
            {isMenuOpen ? (
              <X className={isDarkMode ? "text-red-500" : "text-yellow-300"} />
            ) : (
              <Menu className={isDarkMode ? "text-red-500" : "text-yellow-300"} />
            )}
          </button>

          {/* Desktop Navigation */}
          <nav className="hidden md:flex items-center gap-6">
            <button
              onClick={handleNavClick('countdown')}
              className={`flex items-center gap-2 px-3 py-1 rounded-full transition-all ${
                isDarkMode 
                  ? 'hover:bg-red-900/30 text-red-400' 
                  : 'hover:bg-blue-900/30 text-yellow-300'
              }`}
            >
              <Clock className="w-4 h-4" />
              <span>Countdown</span>
            </button>
            <button
              onClick={handleNavClick('gallery')}
              className={`flex items-center gap-2 px-3 py-1 rounded-full transition-all ${
                isDarkMode 
                  ? 'hover:bg-red-900/30 text-red-400' 
                  : 'hover:bg-blue-900/30 text-yellow-300'
              }`}
            >
              <Image className="w-4 h-4" />
              <span>Gallery</span>
            </button>
            <button
              onClick={handleNavClick('highlights')}
              className={`flex items-center gap-2 px-3 py-1 rounded-full transition-all ${
                isDarkMode 
                  ? 'hover:bg-red-900/30 text-red-400' 
                  : 'hover:bg-blue-900/30 text-yellow-300'
              }`}
            >
              <Video className="w-4 h-4" />
              <span>Highlights</span>
            </button>

            {/* Theme Toggle */}
            <button
              onClick={toggleTheme}
              className={`flex items-center gap-2 px-4 py-2 rounded-full transition-all duration-300 ${
                isDarkMode 
                  ? 'bg-red-900/20 hover:bg-red-900/40' 
                  : 'bg-blue-900/20 hover:bg-blue-900/40'
              }`}
            >
              {isDarkMode ? (
                <>
                  <span className="text-red-500 text-sm">Sharingan Mode</span>
                  <Moon className="text-red-500 w-6 h-6" />
                </>
              ) : (
                <>
                  <span className="text-yellow-300 text-sm">Pirate Mode</span>
                  <Sun className="text-yellow-300 w-6 h-6" />
                </>
              )}
            </button>
          </nav>
        </div>

        {/* Mobile Navigation */}
        {isMenuOpen && (
          <div className="md:hidden absolute top-full left-0 right-0 bg-black/90 backdrop-blur-sm">
            <div className="container mx-auto px-4 py-4 flex flex-col gap-4">
              <button
                onClick={handleNavClick('countdown')}
                className={`flex items-center gap-2 px-3 py-2 rounded-lg transition-all ${
                  isDarkMode ? 'text-red-400' : 'text-yellow-300'
                }`}
              >
                <Clock className="w-4 h-4" />
                <span>Countdown</span>
              </button>
              <button
                onClick={handleNavClick('gallery')}
                className={`flex items-center gap-2 px-3 py-2 rounded-lg transition-all ${
                  isDarkMode ? 'text-red-400' : 'text-yellow-300'
                }`}
              >
                <Image className="w-4 h-4" />
                <span>Gallery</span>
              </button>
              <button
                onClick={handleNavClick('highlights')}
                className={`flex items-center gap-2 px-3 py-2 rounded-lg transition-all ${
                  isDarkMode ? 'text-red-400' : 'text-yellow-300'
                }`}
              >
                <Video className="w-4 h-4" />
                <span>Highlights</span>
              </button>
            </div>
          </div>
        )}
      </div>

      {/* Main Content */}
      <div className="relative z-10 pt-16">
        <WeddingTimeline 
          weddingDate="2024-11-16T10:30:00+05:30" 
          userInteracted={userInteracted}
        />
      </div>
    </div>
  );
};

export default App;