// AnimeEffects.jsx
import React from 'react';
import { motion } from 'framer-motion';
import { Flame, Wind, Scroll, Anchor } from 'lucide-react';

const AnimeEffects = ({ isDarkMode }) => {
  return (
    <div className="fixed inset-0 pointer-events-none z-10">
      {/* Itachi's crows for dark mode */}
      {isDarkMode && (
        <div className="absolute inset-0">
          {Array.from({ length: 6 }).map((_, i) => (
            <motion.div
              key={`crow-${i}`}
              className="absolute"
              initial={{ x: '-10%', y: Math.random() * 100 + '%', opacity: 0 }}
              animate={{
                x: '120%',
                y: Math.random() * 100 + '%',
                opacity: [0, 1, 0],
                rotate: 360,
              }}
              transition={{
                duration: Math.random() * 8 + 4,
                repeat: Infinity,
                delay: i * 2,
              }}
            >
              <div className="w-4 h-4 bg-red-900/40 transform rotate-45" />
            </motion.div>
          ))}
          
          {/* Sharingan Background Effect */}
          <motion.div 
            className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
            animate={{ rotate: 360 }}
            transition={{ duration: 20, repeat: Infinity, ease: "linear" }}
          >
            <div className="w-96 h-96 border-2 border-red-500/5 rounded-full" />
            <div className="absolute inset-0 border-2 border-red-500/5 rounded-full transform rotate-[30deg]" />
            <div className="absolute inset-0 border-2 border-red-500/5 rounded-full transform rotate-[60deg]" />
          </motion.div>
        </div>
      )}

      {/* One Piece effects for light mode */}
      {!isDarkMode && (
        <>
          {/* Waves effect */}
          <div className="absolute bottom-0 left-0 right-0">
            {Array.from({ length: 3 }).map((_, i) => (
              <motion.div
                key={`wave-${i}`}
                className="absolute bottom-0 left-0 right-0 h-32"
                style={{
                  background: `rgba(59, 130, 246, ${0.15 - i * 0.05})`,
                  translateY: i * 10,
                }}
                animate={{
                  translateX: ['-5%', '5%', '-5%'],
                }}
                transition={{
                  duration: 5 + i,
                  repeat: Infinity,
                  ease: 'easeInOut',
                }}
              />
            ))}
          </div>

          {/* Floating clouds */}
          {Array.from({ length: 3 }).map((_, i) => (
            <motion.div
              key={`cloud-${i}`}
              className="absolute"
              style={{
                top: `${20 + i * 30}%`,
                left: '-20%',
              }}
              animate={{
                x: ['0%', '120%'],
              }}
              transition={{
                duration: 15 + i * 5,
                repeat: Infinity,
                ease: 'linear',
                delay: i * 2,
              }}
            >
              <div className="w-20 h-8 bg-white/10 rounded-full" />
            </motion.div>
          ))}
        </>
      )}

      {/* Theme-specific floating elements */}
      {isDarkMode ? (
        // Itachi's flames
        <div className="absolute top-0 right-0 w-32 h-screen">
          {Array.from({ length: 5 }).map((_, i) => (
            <motion.div
              key={`flame-${i}`}
              className="absolute"
              style={{ top: `${20 * i}%` }}
              animate={{
                y: [-10, 10],
                opacity: [0.3, 0.6, 0.3],
              }}
              transition={{
                duration: 2 + i,
                repeat: Infinity,
                ease: 'easeInOut',
                delay: i * 0.2,
              }}
            >
              <Flame className="w-6 h-6 text-red-500/20" />
            </motion.div>
          ))}
        </div>
      ) : (
        // One Piece themed elements
        <div className="absolute top-0 right-0 w-32 h-screen">
          {Array.from({ length: 5 }).map((_, i) => (
            <motion.div
              key={`anchor-${i}`}
              className="absolute"
              style={{ top: `${20 * i}%` }}
              animate={{
                rotate: [-10, 10],
                scale: [1, 1.1, 1],
              }}
              transition={{
                duration: 3 + i,
                repeat: Infinity,
                ease: 'easeInOut',
                delay: i * 0.3,
              }}
            >
              {i % 2 === 0 ? (
                <Anchor className="w-6 h-6 text-yellow-300/20" />
              ) : (
                <Wind className="w-6 h-6 text-blue-300/20" />
              )}
            </motion.div>
          ))}
        </div>
      )}
    </div>
  );
};

export default AnimeEffects;